import React, { JSX } from 'react';
import { Navigate } from 'react-router-dom';

import Cookies from 'js-cookie';

interface ProtectedRouteProps {
  path?: string;
  element: JSX.Element;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ element }) => {
  
  const userData = localStorage.getItem("userData") || "";
  const parsedUserData = userData ? JSON.parse(userData) : null;

  const cookie = Cookies.get('auth')
  
  const token = cookie || parsedUserData?.token

  return !!token ? (
    <>
      {element}
    </>
  ) : (
    <Navigate to="/auth" replace />
  );
};

export default ProtectedRoute;