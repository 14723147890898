import { useCallback } from 'react';
import Cookies from 'js-cookie';

// export const CookieHelper = () => {
//     const setItem = (key: string, value: string) => {
//         Cookies.set(key, value)
//     }

//     const getItem = useCallback((key: string) => {
//         return Cookies.get(key) ?? ''
//     },[])

//     const removeItem = (key: string) => {
//         Cookies.remove(key)
//     }

//     const clear = () => {
//         var cookies = Cookies.get();
//         for (var cookie in cookies) {
//             Cookies.remove(cookie);
//         }
//     }
    
//     return {setItem, getItem, removeItem, clear}
// }

export const LocalStorageHelper = () => {
  const setItem = (key: string, value: string) => {
      localStorage.setItem(key, value)
  }

  const getItem = useCallback((key: string) => {
      return localStorage.getItem(key) ?? ''
  },[])

  const removeItem = (key: string) => {
      localStorage.removeItem(key)
      Cookies.remove('auth')
  }

  const clear = () => {
    localStorage.clear()
  }
  
  return {setItem, getItem, removeItem, clear}
}