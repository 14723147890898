export function AddTeamMemberModal ({newTeamName, setNewTeamName, setIsModalOpen, addTeamMember}: any) {
  return (
    <>
      <h2 className="text-xl font-bold mb-4">Add Team Member</h2>
      <input
        type="text"
        placeholder="Team Name"
        value={newTeamName}
        onChange={(e) => setNewTeamName(e.target.value)}
        className="border px-2 py-1 w-full mb-4"
      />
      
      <div className="flex">
        <button
          className="px-4 py-2 bg-gray-300 rounded mr-2"
          onClick={() => setIsModalOpen(false)}
        >
          Cancel
        </button>
        <button
          className="px-4 py-2 bg-primary text-white rounded"
          onClick={addTeamMember}
        >
          Add
        </button>
      </div>
    </>
  )
}
