
import { useContext, useEffect, useState } from 'react';
import { fetcher } from '../../utils/api'
import { useNavigate } from 'react-router-dom'
import AuthContext from '../../context/Auth/authContext';
// import { useToast } from 'src/components/ui/toast';

const AuthPage: React.FC = () => {

  const navigate = useNavigate()
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [signupCode, setSignupCode] = useState('');
  const [hasSignUpCode, setHasSignUpCode] = useState<boolean>(false)
  const [error, setError] = useState('');
  
  // const { showToast } = useToast()
  
  const { login } = useContext(AuthContext);

  const handleLogin = async (e: React.FormEvent) => {
    e.preventDefault();
    // showToast('User logged in')
    try {
      const url = hasSignUpCode ? '/auth/signup' : '/auth/login'
      const res = await fetcher(url, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include',
        body: JSON.stringify({ email, password, signupCode }),
      });
      login(res.user)
      navigate('/teams');
      
      
    } catch (err: any) {
      setTimeout(() => {
        setError('')   
      },3000)
      setError(err.message);
    }
  };
  
  const verifyAuthOnLandingPage = async () => {
    try {
      const res = await fetcher('/auth/verify', {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include'
      });
      
      console.log('resss-=>', res)
      
      if(res.message === 'Authorized') navigate('/teams');
    } catch (err: any) {
      console.log(err.message)
    }
  }
  
  useEffect(() => {
    verifyAuthOnLandingPage()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  return (
    <div className="flex min-h-screen items-center justify-center bg-gray-100">
      <div className="w-full max-w-sm p-6 bg-white shadow-md rounded-lg">
        {/* Logo */}
        <div className="text-center mb-6">
          <img
            src="/logo-icons/logo.webp" // Replace this with the path to your logo
            alt="Logo"
            className="mx-auto h-12 w-auto"
          />
        </div>

        {/* Form */}
        <form className="space-y-4" onSubmit={handleLogin}>
          <div>
            <label htmlFor="email" className="block text-sm font-medium text-gray-700">
              Email
            </label>
            <input
              type="email"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              placeholder="Enter your email"
              className="mt-1 w-full rounded-md border border-gray-300 p-2 text-gray-900 shadow-sm focus:border-primary focus:ring-primary"
            />
          </div>
          <div>
            <label htmlFor="password" className="block text-sm font-medium text-gray-700">
              Password
            </label>
            <input
              type="password"
              id="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
              placeholder="Enter your password"
              className="mt-1 w-full rounded-md border border-gray-300 p-2 text-gray-900 shadow-sm focus:border-primary focus:ring-primary"
            />
          </div>
          
          <div>
            <label className="inline-flex items-center me-5 cursor-pointer">
              <span className="mr-3 text-sm font-medium text-gray-500 dark:text-gray-300">Do you have <b>AvaSwift AI Code ?</b></span>
              <input type="checkbox" value="" className="sr-only peer" onChange={(e) => setHasSignUpCode(e.target.checked)}/>
              <div className="relative w-9 h-5 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-orange-300 dark:peer-focus:ring-orange-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-4 after:w-4 after:transition-all dark:border-gray-600 peer-checked:bg-primary"></div>
            </label>
          </div>
          
          {hasSignUpCode && <div>
            <input
              type="number"
              id="otp"
              value={signupCode}
              onChange={(e) => setSignupCode(e.target.value)}
              placeholder="AvaSwift AI Code"
              min={0}
              max={999999}
              className="mt-1 w-full rounded-md border border-gray-300 p-2 text-gray-900 shadow-sm focus:border-primary focus:ring-primary"
            />
          </div>}
          {error && <p className="text-red-500">{error}</p>}
          <button
            type="submit"
            className="w-full rounded-md bg-primary px-4 py-2 text-white hover:bg-opacity-90 focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2"
          >
            {hasSignUpCode ? 'Signup' : 'Login'}
          </button>
        </form>
      </div>
    </div>
  );
};

export default AuthPage;
