
export const API_URL = process.env.REACT_APP_BASE_API;

console.log('api url', API_URL)

export const fetcher = async (url: string, options: any = {}) => {
  
  const userData = localStorage.getItem("userData") || "";
  const parsedUserData = userData ? JSON.parse(userData) : null;
  
  if(parsedUserData?.token) options.headers['Authorization'] = `${parsedUserData.token}` 
  
  const res = await fetch(`${API_URL}${url}`, options);
  if (!res.ok) {
    const error = await res.json();
    throw new Error(error.message);
  }
  return res.json();
};