import {
  createBrowserRouter,
  Navigate,
  RouterProvider,
} from "react-router-dom";

import TeamDetailsPage from "../pages/Teams/TeamDetailsPage";
import TeamsPage from "../pages/Teams/TeamsPage";

import AuthPage from "../pages/auth/AuthPage";

import NotFoundPage from "../pages/NotFoundPage/NotFoundPage";
import ProtectedRoute from "./ProtectedRoute";

const router = createBrowserRouter(
  [
    {
      path: "/auth",
      element: <AuthPage/>,
    },
    
    {
      path: "/teams",
      element: (
        <ProtectedRoute path="" element={<TeamsPage />} />
      )
    },
    
    {
      path: "/teams/:teamId",
      element: (
        <ProtectedRoute path="" element={<TeamDetailsPage />} />
      )
    },
    {
      path: "/",
      element: <Navigate to="/auth" />,
    },

    { path: "*", element: <NotFoundPage /> },
  ],
  { basename: "/" }
);

function AppRoutes() {
  return <RouterProvider router={router} />;
}

export default AppRoutes;
