'use client'
import { useState, useEffect, useContext } from 'react';
import Navbar from '../../components/Navbar';
import { fetcher } from '../../utils/api';
import AuthContext from '../../context/Auth/authContext';
import ViewPendingInvitationsModal from '../../components/teamsPageComponents/ViewPendingInvitationsModal';
import { AddTeamMemberModal } from '../../components/teamsPageComponents/AddTeamMemberModal';
import ViewInvitationCodeModal from '../../components/teamsPageComponents/ViewInvitationCodeModal';
import Dropdown from '../../components/Dropdown';
import { useParams } from 'react-router-dom';

interface User {
  _id: string;
  email: string;
  role?: string;
}

interface TeamMember {
  role: string
  user: User
  _id: string
}

interface TeamDetails {
  name: string;
  creatorAdmin: User;
  members: any[]
}

const TeamDetailsPage = () => {
  
  const [teamDetails, setTeamDetails] = useState<TeamDetails>();
  const [pendingInvitations, setPendingInvitations] = useState<any[]>([])
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages] = useState(1);
  const [isModalOpen, setIsModalOpen] = useState<{open: boolean, modalType: string}>({open: false, modalType: ''});
  const [newTeamName, setNewTeamName] = useState('');
  const [invitationCode, setInvitationCode] = useState<number | null>()
  const {state: userState} = useContext(AuthContext)
  
  const {teamId: teamIdFromParams} = useParams()
  
  const fetchTeamDetails = async () => {
    try {
      
      const res = await fetcher(`/teams/${teamIdFromParams}`, {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include'
      });
      
      console.log('get team details response-=>', res)
      if(res.name) setTeamDetails(res)
      
      // if(res.message === 'Authorized') router.push('/dashboard');
    } catch (err: any) {
      console.log(err.message)
    }
  }
  
  const fetchPendingInvitations = async () => {
    try {
      
      const res = await fetcher(`/teams/getPendingInvitations/${teamIdFromParams}`, {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include'
      });
      
      console.log('get team details response-=>', res)
      if(res) setPendingInvitations(res)
    } catch (err: any) {
      console.log(err.message)
    }
  }
  
  const addTeamMember = async () => {
    const res = await fetcher('/teams/addTeamMember', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      credentials: 'include',
      body: JSON.stringify({ email: newTeamName, teamId: teamIdFromParams }),
    });
    
    if(res.invitationCode) {
      setIsModalOpen({open: true, modalType: 'viewInvitationCodeModal'});
      setInvitationCode(res.invitationCode)
    } else {
      setIsModalOpen({open: false, modalType: ''});
    }  
    setNewTeamName('');
    fetchTeamDetails();
    fetchPendingInvitations();
  };
  
  
  const deleteTeamMember = async (userIdToBeDeleted: string) => {
    try {
      
      const res = await fetcher(`/teams/deleteTeamMember/${teamIdFromParams}/${userIdToBeDeleted}`, {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include'
      });
      
      console.log('get team details response-=>', res)
      if(res) {
        const filteredTeamMembers = teamDetails?.members.filter((x) => x.user._id !== userIdToBeDeleted)
        const updatedObject: any = {
          ...teamDetails,
          members: filteredTeamMembers
        }
        setTeamDetails(updatedObject)
      }
    } catch (err: any) {
      console.log(err.message)
    }
  }
  
  
  const changeUserRole = async (newRole:any, userId: any) => {
    // The above user ID is of the user whose role is to be changed  
    const res = await fetcher('/teams/changeUserRole', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      credentials: 'include',
      body: JSON.stringify({ teamId: teamIdFromParams, userId,  newRole}),
    });
    
    if(res) fetchTeamDetails()
  }
  
  useEffect(() => {
    fetchTeamDetails()
    fetchPendingInvitations()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])
  
  console.log('team memeber', teamDetails?.members)
  
  console.log('userstate ===>', userState)
  
  return (
    <>
    <Navbar/>    
    <div className="p-4 bg-slate-50 min-h-screen">
      <h1 className="text-2xl font-bold mb-4">Teams Details - <span className='text-gray-500 capitalize'>({teamDetails?.name})</span></h1>
      <div className='py-3 flex justify-between items-center border-2 border-b-0 bg-white'>
        <div>
          {userState.role === 'superAdmin' && <button
          className="px-4 py-2 text-sm font-bold rounded text-blue-600"
          onClick={() => setIsModalOpen({open:true, modalType: 'addTeamMember'})}
          >
            Add Member
          </button>}
        </div>
        
        {/* <div>
          <h1 className="text-lg font-bold"></h1>
        </div>
         */}
         
        <div>
          {userState.role === 'superAdmin' && <button
          className="px-4 py-2  text-sm font-bold rounded text-blue-600"
          onClick={() => setIsModalOpen({open:true, modalType: 'viewPendingInvitationsModal'})}
          >
            Pending Invitations {pendingInvitations?.length > 0 && `- (${pendingInvitations.length})` }
          </button>}
        </div>
      </div>
      

      <table className="min-w-full bg-white border">
        <thead>
          <tr>
            <th className="border px-4 py-2 text-gray-800 text-base font-semibold text-left">Team Members</th>
            <th className="border px-4 py-2 text-gray-800 text-base font-semibold text-left">Role</th>
            {userState.role !== 'user' && <th className="border px-4 py-2 text-gray-800 text-base font-semibold">Actions</th>}
          </tr>
        </thead>
        <tbody>
          {teamDetails?.members.map((x: TeamMember) => (
            <tr key={x._id} className='text-sm text-gray-500 font-semibold'>
              <td className="border px-4 py-2">{x.user.email}</td>
              <td className="border px-4 py-2 capitalize text-left">
                {userState.role !== 'user'
                ?  <Dropdown name={'role'} value={x?.role} id={x.user._id} onValueChange={changeUserRole} userRole={userState.role}/>
                : <>{x.role}</>
                }
              </td>
             {userState.role !== 'user' && <td className="border px-4 py-2 text-center">
                {x.role !== 'superAdmin' && userState.role !== 'user' && <button
                  className="px-2 py-1 bg-white rounded font-bold text-red-500"
                  onClick={() => deleteTeamMember(x.user._id)}
                >
                  DELETE
                </button>}
              </td>}
            </tr>
          ))}
        </tbody>
      </table>

      {/* Pagination */}
      <div className="mt-4 flex justify-between">
        <button
          disabled={currentPage === 1}
          onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
          className={`px-4 py-2 rounded ${
            currentPage === 1 ? 'bg-gray-300' : 'bg-primary text-white'
          }`}
        >
          Previous
        </button>
        <span>
          Page {currentPage} of {totalPages}
        </span>
        <button
          disabled={currentPage === totalPages}
          onClick={() =>
            setCurrentPage((prev) => Math.min(prev + 1, totalPages))
          }
          className={`px-4 py-2 rounded ${
            currentPage === totalPages ? 'bg-gray-300' : 'bg-primary text-white'
          }`}
        >
          Next
        </button>
      </div>

      {/* Modal */}
      {isModalOpen.open && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
          <div className="bg-white p-4 rounded">
            {isModalOpen.modalType === 'addTeamMember' && <AddTeamMemberModal newTeamName={newTeamName} setNewTeamName={setNewTeamName} setIsModalOpen={setIsModalOpen} addTeamMember={addTeamMember} />}
            {isModalOpen.modalType === 'viewInvitationCodeModal' && <ViewInvitationCodeModal invitationCode={invitationCode} setIsModalOpen={setIsModalOpen} />}
            {isModalOpen.modalType === 'viewPendingInvitationsModal' && <ViewPendingInvitationsModal pendingInvitations={pendingInvitations} setIsModalOpen={setIsModalOpen} setPendingInvitations={setPendingInvitations} />}
          </div>
        </div>
      )}
    </div>
  </>
    
  )
}

export default TeamDetailsPage

