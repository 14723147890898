/* eslint-disable jsx-a11y/anchor-is-valid */
'use client'
import { useState, useEffect, useContext } from 'react';
import Navbar from '../../components/Navbar';
import { fetcher } from '../../utils/api';
import AuthContext from '../../context/Auth/authContext';
import { useNavigate } from 'react-router-dom';

interface Team {
  id: string;
  name: string;
  admin: string;
}

const TeamsPage = () => {
  const [teams, setTeams] = useState<Team[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [newTeamName, setNewTeamName] = useState('');
  const [wandbToken, setWandbToken] = useState('')
  const navigate = useNavigate();
  const {state: userState} = useContext(AuthContext)
  

  // Fetch teams data (assumes server-side pagination API)
  const fetchTeams = async (page: number) => {

    const res = await fetcher(`/teams?page=${page}`, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
      credentials: 'include'
    });
    
    setTeams(res.teams);
    setTotalPages(res.totalPages);
  };

  const handleCreateTeam = async () => {
  
    const res = await fetcher('/teams', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      credentials: 'include',
      body: JSON.stringify({ name: newTeamName, wandbToken }),
    });
    
    if(res.creatorAdmin) {
      setNewTeamName('');
      setWandbToken('')
      setIsModalOpen(false);
      fetchTeams(currentPage);
    }    
  };
  
  const navigateToZella = async (teamId: string) => {
    const res = await fetcher(`/teams/navigateTeamUserToZella?teamId=${teamId}`, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
      credentials: 'include'
    });
    
    if(res.redirectUrl) {
      window.open(res.redirectUrl, '_blank', 'noopener,noreferrer');
    }
    console.log('respomnse', res)
  }
  
  const navigateToGenie = async (teamId: string) => {
    const res = await fetcher(`/teams/navigateTeamUserToGenie?teamId=${teamId}`, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
      credentials: 'include'
    });
    
    if(res.redirectUrl) {
      window.open(res.redirectUrl, '_blank', 'noopener,noreferrer');
    }
    console.log('respomnse', res)
  }
  
  useEffect(() => {
    fetchTeams(currentPage);
  }, [currentPage]);
  
  console.log('user state' , userState)

  return (
    <>
      <Navbar/>
      <div className="p-4 bg-slate-50 min-h-screen">
        <h1 className="text-2xl font-bold mb-4">Teams</h1>
        {userState.role === 'superAdmin' && <button
          className="mb-4 px-4 py-2 bg-primary text-white rounded"
          onClick={() => setIsModalOpen(true)}
        >
          Create Team
        </button>}

        <table className="min-w-full bg-white border">
          <thead>
            <tr>
              <th className="border px-4 py-2 text-left">Team Name</th>
              <th className="border px-4 py-2 text-left">Wandb Token</th>
              <th className="border px-4 py-2 text-left">Navigate</th>
              <th className="border px-4 py-2">Members</th>
            </tr>
          </thead>
          <tbody>
            {teams.map((team: any) => (
              <tr key={team._id}>
                <td className="border px-4 py-2">{team.name}</td>
                <td className="border px-4 py-2">{team.wandbToken}</td>
                <td className="border px-4 py-2 text-sm">
                  <a href="#" onClick={() => navigateToGenie(team._id)}>Genie</a>
                  <a href="#" className='ml-5' onClick={() => navigateToZella(team._id)}>Zella</a>
                </td>
                <td className="border px-4 py-2 text-center">
                  <button
                    className="px-2 py-1 text-primary text-sm font-bold rounded"
                    onClick={() => navigate(`/teams/${team._id}`)}
                  >
                    View
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        {/* Pagination */}
        <div className="mt-4 flex justify-between">
          <button
            disabled={currentPage === 1}
            onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
            className={`px-4 py-2 rounded ${
              currentPage === 1 ? 'bg-gray-300' : 'bg-primary text-white'
            }`}
          >
            Previous
          </button>
          <span>
            Page {currentPage} of {totalPages}
          </span>
          <button
            disabled={currentPage === totalPages}
            onClick={() =>
              setCurrentPage((prev) => Math.min(prev + 1, totalPages))
            }
            className={`px-4 py-2 rounded ${
              currentPage === totalPages ? 'bg-gray-300' : 'bg-primary text-white'
            }`}
          >
            Next
          </button>
        </div>

        {/* Modal */}
        {isModalOpen && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
            <div className="bg-white p-4 rounded">
              <h2 className="text-xl font-bold mb-4">Create Team</h2>
              <input
                type="text"
                placeholder="Team Name"
                value={newTeamName}
                onChange={(e) => setNewTeamName(e.target.value)}
                className="border px-2 py-1 w-full mb-4"
              />
              
              <input
                type="text"
                placeholder="wandb Token"
                value={wandbToken}
                onChange={(e) => setWandbToken(e.target.value)}
                className="border px-2 py-1 w-full mb-4"
              />
              
              <div className="flex">
                <button
                  className="px-4 py-2 bg-gray-300 rounded mr-2"
                  onClick={() => setIsModalOpen(false)}
                >
                  Cancel
                </button>
                <button
                  className="px-4 py-2 bg-primary text-white rounded"
                  onClick={handleCreateTeam}
                >
                  Create
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default TeamsPage;
