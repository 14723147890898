/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState } from "react";

interface DropDownProps {
  name: string,
  value: string,
  id: string,
  onValueChange: any,
  userRole: string
}

export default function Dropdown({value, id, onValueChange}: DropDownProps) {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="relative inline-block text-left">
      <div>
        <button
          type="button"
          className="inline-flex items-center justify-center w-full rounded-md border border-gray-300 shadow-md px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-[var(--primary-color-light)] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[var(--primary-color)]"
          onClick={() => setIsOpen(!isOpen)}
        >
          {value}
          <svg
            className="ml-2 -mr-1 h-5 w-5 text-[var(--primary-color)]"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            aria-hidden="true"
          >
            <path
              fillRule="evenodd"
              d="M5.23 7.21a.75.75 0 011.06.02L10 10.94l3.71-3.71a.75.75 0 111.06 1.06l-4 4a.75.75 0 01-1.06 0l-4-4a.75.75 0 01.02-1.06z"
              clipRule="evenodd"
            />
          </svg>
        </button>
      </div>

      {isOpen && (
        <div
          className="absolute right-0 z-10 mt-2 w-max origin-top-right rounded-md shadow-lg bg-white ring-1 ring-gray-300 ring-opacity-50 focus:outline-none"
          role="menu"
          aria-orientation="vertical"
          aria-labelledby="menu-button"
        >
          <div className="py-1">
            <a
              href="#"
              className="block px-4 py-2 text-sm text-gray-700 hover:bg-[var(--primary-color-light)]"
              // style={{
              //   '--primary-color-light': '#A5D6A7',
              // }}
              role="menuitem"
              onClick={() => {setIsOpen(false); onValueChange('admin', id)}}
            >
              Admin
            </a>
            <a
              href="#"
              className="block px-4 py-2 text-sm text-gray-700 hover:bg-[var(--primary-color-light)]"
              role="menuitem"
              onClick={() => {setIsOpen(false); onValueChange('user',id)}}
            >
              User
            </a>
          </div>
        </div>
      )}
    </div>
  );
}
