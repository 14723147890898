export default function ViewInvitationCodeModal  ({invitationCode, setIsModalOpen}: any) {
  return (
    <div className='text-center'>
      <h2 className="text-lg font-bold mb-4">Please share the code with the user</h2>
      <p><b className='text-primary'>AvaSwift AI Code - <span>{invitationCode}</span> </b></p>
      <button
        className="px-4 py-2 mt-5 text-sm font-bold bg-primary text-white rounded"
        onClick={() => setIsModalOpen({open: false, modalType:''})}
      >
        Okay
      </button>
    </div>
  )
}