
'use client'
import { useContext, useState } from "react";
import AuthContext from "../context/Auth/authContext";
import { NavLink } from "react-router-dom";

const Navbar = () => {
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [activeDropdown, setActiveDropdown] = useState(null);
  const { logout } = useContext(AuthContext)

  const toggleDropdown = (menu: any) => {
    setActiveDropdown(activeDropdown === menu ? null : menu);
  };
  
  return (
    <nav>
      <div className="max-w-7xl mx-auto px-4 py-3 sm:px-6 md:px-28">
        <div className="flex justify-between items-center h-16">
          {/* Logo */}
          <div className="flex-shrink-0">
            <NavLink to="/teams" className="text-xl font-bold">
              <img src="/logo-icons/logo.webp" alt="Avawtz Logo" style={{height: '70px'}}/>
            </NavLink>
          </div>

          {/* Desktop Menu */}
          <div className="hidden md:flex space-x-8">
            <NavLink to="/teams" className="hover:text-primary px-3 py-2 rounded-md text-sm font-bold">
                Team
            </NavLink>
            <NavLink to="/auth" onClick={() => logout()} className="hover:text-primary px-3 py-2 rounded-md text-sm font-bold">
                Logout
            </NavLink>
          </div>

          {/* Mobile Menu Button */}
          <div className="flex md:hidden">
            <button
              onClick={() => setMobileMenuOpen(!isMobileMenuOpen)}
              className="focus:outline-none"
            >
              <svg
                className="h-6 w-6"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d={isMobileMenuOpen ? "M6 18L18 6M6 6l12 12" : "M4 6h16M4 12h16m-7 6h7"}
                />
              </svg>
            </button>
          </div>
        </div>
      </div>

      {/* Mobile Menu */}
      {isMobileMenuOpen && (
        <div className="md:hidden">
          <NavLink to="/" className="block px-4 py-2 font-bold">
            Home
          </NavLink>
          <div className="relative">
            <button
              onClick={() => toggleDropdown("mobileParent1")}
              className="w-full text-left px-4 py-2  font-bold"
            >
              Parent 1
            </button>
            {activeDropdown === "mobileParent1" && (
              <div className="pl-3 ">
                <NavLink to="/child1" className="block px-4 py-2 font-semibold text-base">
                  Child 1
                </NavLink>
                <NavLink to="/child2" className="block px-4 py-2 font-semibold text-base">
                  Child 2
                </NavLink>
              </div>
            )}
          </div>
          <NavLink to="/about" className="block px-4 py-2 font-bold">
            About
          </NavLink>
        </div>
      )}
    </nav>
  );
};

export default Navbar;
